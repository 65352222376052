import { Poppins, Quicksand } from 'next/font/google';
import React from 'react'
import GetInstantForm from '../header-section/get-instant-form';
import Image from 'next/image';
const popins = Poppins({weight:"400",subsets:['latin'],})
const popinsfw = Poppins({weight:"500",subsets:['latin'],})
const extrapopins = Poppins({weight:"600", subsets:['latin'],});
const quickSand = Quicksand({weight:"400",subsets:['latin'],})
export default function BestInstitute() {
  return (
    <>
    <section className='best-bg pdtb70 mpdtb40 mt70 mmt40'>
       <div className='container'>
         <div className='df gap20 tfdc'>
            {/* Content */}
            <div className=''>
            <h2 className={`fs-40  mfs-24   ${extrapopins.className}`}>Why  <span className='ylw-clr'> Learnsoft.org  </span>is the Best Training Institute in Chennai?</h2>
            {['We have successfully guided over 75,000 students and professionals in launching or transitioning their careers into the IT industry','We limit our batch size to just 5-6 students to ensure personalized attention and focused learning for each individual','Our placement network spans over 1500 companies, and our dedicated team works relentlessly to connect you with top IT job opportunities, helping you land your dream role!'].map((items,index)=>{
                return(
             <div className='df gap10 fac mt22' key={index}>
                     <Image  src="/corporate-training-img/tick.svg" width={24} height={24} alt='Tick Icon' loading='lazy'/>
                     <p className={`fs-19 mfs-16 ${quickSand.className}`}>{items}</p> 
                 </div>
                )
            })} 
            </div>
            {/* Form */}
            <div className=''>
                <GetInstantForm heading={"Quick Enquiry"} formWidth={"505px"} />
            </div>
         </div>
       </div>
    </section>
    <style jsx>
        {`
        .best-bg{
            background-image: url('/home-v2-images/why-choose-bg.webp');
            width:100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
           
            background-size: 100% 100%;
        }
                              @media only screen and (max-width: 1250px){
              .tfdc{
              display: flex;
              flex-direction: column;
              align-items: center;
              }
        }
        `}
    </style>
    </>
  )
}
